@media screen and (max-width: $breakpoint-1100) {
  .graph-intervals__elems-wrap {
    right: -58.7%;
    top: 58.3%;
    width: 230px;
    .graph-intervals__item {
      &.animated:nth-child(-n+5)  {
        transform:translate(255px, 127px);
      }
      &.animated:nth-child(7) {
        transform:translate(255px,60px);
      }
      &.animated:nth-child(11),  &.animated:nth-child(12),  &.animated:nth-child(13), &.animated:nth-child(19), &.animated:nth-child(20), &.animated:nth-child(22) {
        transform:translate(255px,-10px);
      }
      &.animated:nth-child(14), &.animated:nth-child(15),  &.animated:nth-child(16),    &.animated:nth-child(21){
        transform:translate(255px, 0px);
      }
      &.animated:nth-child(17), &.animated:nth-child(18),  &.animated:nth-child(20),  &.animated:nth-child(24) {
        transform:translate(255px,-70px);
      }
      &.animated:nth-child(19), &.animated:nth-child(22), &.animated:nth-child(23) {
        transform:translate(255px,-95px);
      }
      &.animated:nth-child(25) {
        transform:translate(255px,-135px);
      }
    }
  }

  .graph-intervals__start {
    left: 98%;
  }
  .graph-intervals__start-top {
    left: 98%;
    top: 57px;
  }
  .graph-intervals__elems-top {
    right: -58.7%;
    top: -45px;
    width: 230px;
    .graph-intervals__item.animated {
      transition: transform 11s ease-in;
    }
    .graph-intervals__item.animated:nth-child(7),  .graph-intervals__item.animated:first-child {
      display: none;
    }
    .graph-intervals__item.animated:nth-last-child(6) {
      display: none;
    }
  }
  .data__graph-elems-wrap.vertical {
    display: block;
  }
  .data__item-offset {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      max-width: 100px;
      height: 100px;
      background: $baseWhite;
      top: -99px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
  }
  .figures-item {
    top: 41%;
    left: 19%;
    &:nth-child(2) {
      right: 16%;
    }
    font-size: 12px;
  }
  .data__item-col, .data__item-col_short {
    width: 100%;
    max-width: 809px;
    margin: 0 auto;
    .data__item-content {
      margin: 0 auto 80px;
      max-width: 809px;
    }
  }
  .data__item-col_short {
    margin: 0 auto;
    position: relative;
    &:after {
      content: '';
      height: 50px;
      width: 100%;
      position: absolute;
      top: 100%;
      background: $baseWhite;
      left: 0;
    }
  }
  .data__graph-elems, .data__graph-elems:nth-child(2),.data__graph-elems:nth-child(4) {
    display: none
  }
  .data__info-code {
    display: none;
  }
  .data__item.mb-15 {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 25px;
      bottom: -16px;
      left: 0;
      background: $baseWhite;
      z-index: 2;
    }
  }
  .data__graph-vertical  {
    display: block
  }
  .data__item-col_short:before {
    content: '';
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(#e5e5e5));
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, #e5e5e5 100%);
    top: -10px;
    left: 24px;
    position: absolute;
    width: 93%;
    height: 25px;
    z-index: 0;
    -webkit-filter: blur(10px);
    filter: blur(13px);
  }
  .data__item-elems {
    animation: moveDown 6s linear infinite;
    width: auto;
    height: 200%;
    right: auto;
    left: calc(50% - 20px);
    transform: translateX(-50%);
    flex-direction: column;
    z-index: -2;
  }
  .data__item-elem {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    img {
      margin-left: 0;
      margin-bottom: 40px;
      margin-top: 45px;
    }
  }
  .data__item-wrapper {
    position: relative;
    &:after {
      content: '';
      width: 100%;
      height: 9px;
      position: absolute;
      bottom: -3px;
      background: $baseWhite;
      filter: blur(4px);
      border-radius: $borderRadius-10;
    }
  }
}

@media screen and (min-width: $breakpoint-xs-480) and (max-width: $breakpoint-1100) {
  .data__stats-item {
    width: 28.6%;
    &:nth-child(2) {
      svg {
        padding-left: 12px;
        padding-top: 11px;
        max-width: 169px;
      }
    }
    &:nth-child(3) { 
      display: flex;
      svg {
        margin-left: auto;
      }
    }
  }
  .data__stats-item:first-child {
    width: 42%;
  }
  .data__item-body {
    max-width: 693px;
    margin: 0 auto;
    padding-top: 11px;
    padding-bottom: 15px;
  }
  .data__feature {
    width: auto;
  }
  .data__stats-items {
    min-height: auto;
  }
  .data__item_gradient .data__info-descr {
    width: 100%;
    max-width: 248px;
    margin: 0 auto;
  }
  .data__item-offset .data__item {
    max-width: 609px;
    padding-right: 12px;
  }
  .data__item-offset .data__item-title {
    margin-bottom: 22px;
    margin-left: 28px;
  }
  .data__item-offset .data__features-list {
    max-width: 528px;
  }
  .data__item-header {
    background: url(../../static/img/svg/gradient-mob.svg) 50% 58% no-repeat;
    background-size: cover;
  }
  .data__item_gradient .data__item-title {
    margin-bottom: 11px;
  }
  .data__stats-items {
    margin-bottom: 30px;
  }
  .data__features {
    justify-content: space-between;
  }
  .data__feature {
    margin-bottom: 25px;
  }
  .data__item-offset .data__item {
    width: calc(100% - 90px);
  }
}
@media screen and (max-width: $breakpoint-810) {
  .graph-intervals__start, .graph-intervals__elems-wrap {
    bottom: -65px;
    top: auto;
    left: 52%;
    transform: translateX(-50%);
    opacity: 1;
    animation: none;
  }
  .graph-intervals__start-top {
    display: none;
  }
  .graph-intervals__elems-wrap {
    height: 220px;
    left: 62%;
  }
  .data__item.mb-15 .graph-intervals__item.animated {
    transform: translate(0, 240px);
  }
  .data__info .data__info-col:first-child .data__info-item:before {
    content: '';
    position: absolute;
    top: auto;
    right: 0;
    width: 100%;
    height: 5px;
    background: #ffffff;
    -webkit-filter: blur(4px);
    filter: blur(4px);
    bottom: -5px;
  }
  .graph-intervals.centered {
    left: 52%;
    transform: translateX(-50%);
    display: flex;
    height: 150px;
    width: 90px;
    top: calc(100% - 150px);
    z-index: -1;
    .graph-interval p:first-child, .graph-interval p:nth-child(2), .graph-interval p:last-child {
      bottom: 0;
      transform: rotate(0deg);
      top: auto
    }
    .fallen-text {
      transform:translate(0, 220px);
    }
    &:nth-child(2) {
      .fallen-text {
        transform:translate(0, 220px);
      }
    }
  }
  .data__info-item {
    background: $baseWhite;
    border: 1px solid $lightGreen;
    &.data-no-border {
      border-color: transparent
    }
    &.data-text {
      &:before, &:after {
        display: none;
      }
    }
  }
  .data__info-elem-inner {
    margin-top: 1000px;
  }
  .data__graph-wrap {
    display: block;
  }
  .data__item-elem img {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .data__item-elems {
    animation-duration: 8s;
  }
  .data__features-list {
    max-width: 500px;
    margin: 0 auto;
  }
  .data__info-col:nth-child(2n+2) .data__info-item .data__info-item-inner {
    background: $baseWhite;
  }
  .data__info-col:last-child {
    position: relative;
    z-index: 5;
    &:after, &:before {
      content: '';
      width: 100%;
      height: 40px;
      bottom: -40px;
      left: 0;
      position: absolute;
      background: $baseWhite;
      z-index: 2;
    }
    &:before {
      height: 10px;
      top: -10px;
      filter: blur(4px);
    }
  }
  .data__info-col.no-border {
    position: relative;
    &:before {
      content: '';
      width: 100%;
      height: 100px;
      top: -97px;
      left: 0;
      position: absolute;
      background: $baseWhite;
      z-index: 9;
    }
  }
  .data-no-border {
    position: relative;
    z-index: 5;
    &:after {
      content: '';
      width: 100%;
      height: 10px;
      bottom: -10px;
      left: 0;
      position: absolute;
      background: $baseWhite;
      z-index: 2;
      filter: blur(5px);
    }
  }
  .data__graph-elems-wrap {
    display: none;
    &.vertical {
      display: block;
    }
  }
  .data__info-col {
    width: 100%;
    margin-bottom: 53px;
    &:last-child {
      margin-bottom: 0;
    }
    .data__info-item {
      margin: 0 auto;
    }
    &:first-child {
      padding-bottom: 53px;
      margin-bottom: 0;
    }
  }
  .data__item.mb-15 .data__info-col:first-child {
    overflow: hidden;
  }
  .data__info-col:nth-child(3n+2) .data__info-item, .data__info-col:nth-child(2n+3) .data__info-item {
    margin: 0 auto;
  }
  .data__info-item {
    max-width: 500px;
  }
  .data__features-item {
    width: 33.33%;
    margin-bottom: 17px;
  }
  .data__item-title {
    font-size: 25px;
  }
  .data__item-offset-inner {
    display: flex;
  }
  .data__item-offset {
    padding-left: 0;
    .data__item-title {
      width: 100%;
      margin-bottom: 14px;
      margin-left: 0;
    }
    .data__item {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 90px);
      padding: 25px 11px 20px;
    }
    .data__item-info {
      width: 50%;
    }
    .data__item-pic {
      position: static;
      width: 44%;
      .profile {
        margin: 0 auto;
      }
    }
  }
  .data__item-offset .data__features-list {
    max-width: 100%;
  }
  .data__features-item.flex {
    width: 50%;
    margin-bottom: 15px;
  }
  .data__features-item.flex:last-child {
    padding-left: 0;
  }
  .data__graph-vertical {
    animation-duration: 20s;
  }
}
@media screen and (max-width: $breakpoint-625) {
  .figures-item {
    left: 18%;
    &:nth-child(2) {
      right: 14.2%;
    }
    font-size: 12px;
  }
}
  @media screen and (max-width: $breakpoint-xs-480) {
    .data__info-elem-inner {
      margin-top: 1300px;
    }
   .figures-item {
     top: 35%;
     left: 20%;
     &:nth-child(2) {
       right: 16%;
     }
  }
  .data__item-elems {
    animation: moveDownMob 6s linear infinite;
    left: calc(50% - 50px);
  }
  .data__item.mb-15 {
     padding-top: 36px;
     padding-bottom: 40px;
     margin-bottom: 70px;
    .data__item-title {
      margin-bottom: 40px;
    }
    .data__features-item {
      display: flex;
    }
  }
  .data__info {
    margin-top: 42px;
  }
  .data__info-item {
    max-width: 232px;
   }
  .data__info-item.data-no-border .data__info-img-wrap {
    min-height: 248px;
  }
  .data__item-offset .data__item-pic{
    width: 50%;
    padding-right: 10px;
  }
  .data__info-item-inner {
    padding-top: 28px;
  }
  .data__info-title {
    margin-bottom: 31px;
  }
  .data__info-feature {
    height: 86px;
  }
  .data__info-features {
    margin-bottom: 21px;
  }
  .data__item-offset .data__item-pic .profile {
    margin-left: 0;
  }
  .data__features-item.flex {
    width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-child(4) {
      display: none;
    }
  }
  .profile {
    width: 90px;
    height: 90px;
  }
  .data__features-item.flex.mobile-show {
    display: flex;
    margin-top: auto;
  }
  .data__item-pic {
    display: flex;
    flex-direction: column;
  }
  .data__item-offset-inner {
    max-width: 205px;
    margin: 0 auto;
  }
  .data__item-header {
    min-height: 87px;
  }
  .data__stats-item:first-child {
    margin-bottom: 9px;
  }
  .data__stats-items {
    margin:0 -2px 43px;
  }
  .data__feature-title {
    font-size: 16px
  }
  .data__feature {
    margin-bottom: 39px;
  }
  .data__item_gradient .data__info-descr {
    min-height: 50px;
    margin-top: 0;
  }
  .data__item-body {
    padding-bottom: 13px;
  }
  .data__item-header {
    background-position: 50% 100%;
  }
}


@media screen and (max-width: $breakpoint-xxs) {
  .data__item-offset .data__item-pic,   .data__item-info {
    padding-left: 2px;
  }
  .data__item.mb-15 .data__features-item {
    width: 50%;
  }
  .figures-wrap .figures-item {
    top: 32%;
    left: 18%;
    &:nth-child(2) {
      left: auto;
      right: 14%;
    }
  }
}