@keyframes move {
  0% { right: -100%; }
  100% { right: -200%; }
}
@keyframes moveDouble {
  0% { right: -70px; }
  100% { right: -9000px; }
}
@keyframes moveVertcal {
  0% { top: -9500px; }
  100% { top: -20px; }
}
@keyframes moveUser {
  0% { right: -100%; }
  100% { right: -211%; }
}
@keyframes moveDown {
  0% { top: -100%; }
  100% { top: 200%; }
}
@keyframes moveDownMob {
  0% { top: 10%; }
  100% { top: 130%; }
}
@keyframes numberDown-1 {
  0% { top: 0%; }
  100% { top: 100%; }
}
@keyframes opacityShow {
  0% {
   opacity: .8;
  }
  100% {
    opacity: 0;
  }
}
@keyframes moveCentered {
  0% { right: -10%; opacity: 1 }
  100% { right: -130%; opacity: 1 }
}
@keyframes moveForward {
  0% { top: 100%; right: -100%; opacity: 0.2; }
  70% { opacity:1; }
  100% { top:200%; right: -180%; opacity:1;}
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  60% {
    stroke-dashoffset: 0;
  }
  1000% {
    stroke-dashoffset: 1000;
  }
}