.header {
  width: 100%;
  height: vw(70px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &--wrap {
    width: 100%;
    background-color: aquamarine;
  }
}