// Max breakpoint
$pc: 1440;
$desktop: 768;
$mob: 320;

// Function
@function vw($target, $digits:2) {
  $n: 1;
  @for $i from 1 through $digits {
    $n: $n * 10; // how point after
  }
  @return round($target/(($pc * .01) * 1px) * $n) / $n * 1vw;
}

@function dvw($target, $digits:2) {
  $n: 1;
  @for $i from 1 through $digits {
    $n: $n * 10; // how point after
  }
  @return round($target/(($desktop * .01) * 1px) * $n) / $n * 1vw;
}

@function mvw($target, $digits:2) {
  $n: 1;
  @for $i from 1 through $digits {
    $n: $n * 10; // how point after
  }
  @return round($target/(($mob * .01) * 1px) * $n) / $n * 1vw;
}

@mixin linerText {
  color: linear-gradient(139.9deg, #73B98D 11.81%, #B0D05F 83.71%);
}