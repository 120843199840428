.data {
  &-section {
    margin: 40px 0;
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  &__item {
    border-radius: $borderRadius-10;
    box-shadow: $boxShadow;
    overflow: hidden;
    background: $baseWhite;
    padding: 20px;
    z-index: 10;
    &-title {
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      color: $baseGreen;
      text-transform: capitalize;
      margin-bottom: 21px;
      &.white {
        color: $baseWhite;
      }
    }
    &-col {
      padding: 0 15px;
      width: 70%;
      &_short {
        width: 30%;
        max-width: 303px;
        position: relative;
        margin-left: auto;
        &:before {
          content: '';
          background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(229,229,229,1) 100%);
          background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(229,229,229,1) 100%);
          background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(229,229,229,1) 100%);
          top: 50%;
          left: -38px;
          position: absolute;
          width: 117px;
          height: 90%;
          transform: translateY(-50%);
          z-index: 0;
          filter: blur(10px);
        }
        &:after {
          content: '';
          position: absolute;
          z-index: 2;
          width: 15px;
          height: 80%;
          right: 0;
          bottom: 0;
          background: $baseWhite;
        }
        .data__item {
          height: 100%;
          position: relative;
        }
      }
    }
  }
  &__features-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -3px;
  }
  &__features-item {
    padding: 0 3px;
  }
  &__features-item-text {
    margin-left: 10px;
    font-weight: bold;
    text-transform: capitalize;
    color: $baseGreen;
  }
  &__info-descr {
    padding: 6px;
    border-radius: $borderRadius-8;
    background: $gradientBg;
    text-align: center;
    text-transform: lowercase;
    color: $baseGreen;
    .bold {
      font-weight: bold;
      padding: 0 3px;
      text-transform: capitalize;
    }
  }
  &__info {
    display: flex;
    flex-wrap: wrap;
    margin: 34px -10px 0;
    &-col {
      width: 33.33%;
      padding: 0 10px;
      overflow: hidden;
      &:nth-child(2n+2) {
        .data__info-item {
          margin: 0 auto;
          position: relative;
        }
      }
      &:nth-child(2n+3) {
        .data__info-item {
          margin: 0;
          margin-left: auto;
          position: relative;
          overflow: visible;
          &:after {
            content: '';
            width: 20px;
            height: 100%;
            position: absolute;
            z-index: 2;
            right: -20px;
            top: 0;
            background: $baseWhite;
          }
        }
      }
    }
  }
  &__info-item {
    border-radius: $borderRadius-10;
    overflow: hidden;
    background: $gradientBorder;
    padding: 1px;
    max-width: 200px;
    height: 100%;
    &-inner {
      border-radius: $borderRadius-10;
      background: $baseWhite;
      padding: 20px 5px 5px;
      height: 100%;
    }
  }
  &__info-features {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 13px;
    padding: 0 15px;
  }
  &__info-feature {
    width: 50%;
  }
  &__info-title {
    font-size: 16px;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 20px;
    background: linear-gradient(139.9deg, #73B98D 11.81%, #B0D05F 83.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    z-index: 3;
  }
}
.data__info-feature {
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $lightGreen;
  &:first-child, &:nth-child(2) {
    height: 42px;
    align-items: baseline;
  }
  &:last-child, &:nth-child(5) {
    height: 55px;
    border-bottom: 0;
  }
  &:nth-child(2n) {
    border-left: 1px solid $lightGreen;
  }
}
.data-text .data__info-feature-text {
  max-width: 45px;
  text-align: center;
  color: $baseGrey;
}
.data__info-item.data-no-border {
  background: transparent;
  .data__info-img-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
  }
  .data__info-title {
    margin-bottom: 0;
  }
}
.data__info-graph {
  width: 62px;
  height: 17px;
  background: $secondaryGreen;
  padding: 2px;
  overflow: hidden;
  border-radius: $borderRadius-5;
  margin-top: -1px;
  &-inner {
    height: 100%;
    background: $baseWhite;
    border-radius: $borderRadius-3;
    overflow: hidden;
  }
  &-elems {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    max-width: 47px;
    margin: 0 auto;
  }
  &-elem {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $lightGreen;
  }
}
.data__info-img {
  margin-top: -1px;
  position: relative;
  .data__info-img-block {
    width: 150%;
    height: 100px;
    background: $baseWhite;
    position: absolute;
    bottom: -97px;
    left: -6px;
    z-index: -1;
    filter: blur(3px);
  }
}
.data__info-col:nth-child(2n+2) {
  .data__info-item {
    position: relative;
    .data__info-item-inner {
      background: transparent;
    }
  }
}
.data__info-item-inner {
  position: relative;
  z-index: 5;
}
.data__item.mb-15 {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 100%;
    background: $baseWhite;
    z-index: 1;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20px;
    background: $baseWhite;
    z-index: 1;
  }
  .data__info-col {
    overflow: visible;
  }
}
.data__features-item.flex {
  display: flex;
  align-items: center;
  padding: 0 2px;
  width: 20%;
  &:last-child {
    padding-left: 20px;
  }
  .data__features-item-text {
    max-width: 75px;
  }
  &:first-child   .data__features-item-text {
    max-width: 40px;
  }
}
.data__item-offset {
  padding: 1px 0 0 70px;
  position: relative;
}
.data__item-content {
  max-width: 729px;
}
.data__item-pic {
  position: absolute;
  top: 0;
  left: 0;
}

.profile {
  background: $gradientBorder;
  padding: 6px;
  border-radius: 50%;
  overflow: hidden;
  width: 131px;
  height: 131px;
  &__img {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
  }
}
.avatar__image, .avatar__img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.data__item-offset {
  min-height: 130px;
  .data__item-title {
    margin-bottom: 20px;
    margin-left: -43px;
  }
  .data__features-list {
      max-width: 558px;
      margin-left: auto;
    }
  .data__item {
    padding: 13px 20px 43px 0px;
  }
}
.data__item-elem img {
  max-height: 65px;
  z-index: -1;
  margin-left: 25px;
}

.data__item_gradient {
  padding: 1px;
  background: $gradientBorder;
  .data__item-inner {
    background: $baseWhite;
    height: 100%;
    border-radius: $borderRadius-10;
    overflow: hidden;
  }
  .data__item-title {
    margin-bottom: 13px;
  }
  .data__info-descr {
    margin-top: auto;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.data__item-header {
  background: url('../../static/img/svg/gradient.svg') 50% 58% no-repeat;
  background-size: cover;
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data__item-body {
  padding: 6px 12px 6px;
}
.data__stats-items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
  min-height: 160px;
}
.data__stats-item {
  padding: 0 10px;
  width: 50%;
  &:first-child {
    width: 100%;
    margin-bottom: 2px;
  }
  &:last-child {
    margin-top: 7px;
  }
}
.data__stats-items {
  margin-bottom: 20px;
}
.data__features {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.data__item-body {
  height: calc(100% - 72px);
  display: flex;
  flex-direction: column;
}
.data__feature {
  padding: 0 5px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
}
.data__feature-icon {
  margin-bottom: 15px;
}
.data__feature-title {
  font-weight: bold;
  font-size: 14px;
  color: $baseGreen;
}
.data__info-item.data-text {
  .data__info-feature:first-child, .data__info-feature:nth-child(2) {
    padding-top: 7px;
  }
}
.data__features-item.flex.mobile-show {
  display: none;
}
.data__item-elem {
  z-index: -1;
}
.data__item-elems {
  animation: moveUser 20s linear infinite;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 200%;
  right: -100%;
  z-index: -1;
  display: flex;
}
.data__item-wrapper {
  position: relative;
}
.data__graph-elem {
  display: flex;
}
.data__graph-elems-wrap {
  animation: moveDouble 230.5s linear infinite;
  position: absolute;
  top: 57%;
  z-index: 0;
  right: -100px;
  width: 10000px;
  height: 9px;
  background: url('../img/svg/code-double1.svg') 50% 50%;
  background-repeat: repeat-x;
  background-position: right top;
  &:nth-child(2) {
    animation: moveDouble 190s linear infinite;
    top: 61%;
  }
  &.vertical {
    display: none;
    animation-name: moveVertcal;
    left: 0;
    width: 6px;
    height: 10000px;
    background-repeat: repeat-y;
    background-position: left top;
    background: url('../img/svg/graph-vertical.svg') 50% 50%;
    &:nth-child(2n) {
      left: auto;
      right: 0;
      animation-duration: 190s;
    }
  }
}
.data__graph-elem-items {
  display: flex;
}
.data__graph-elems {
  animation: move 15s linear infinite;
  position: absolute;
  top: 61%;
  z-index: -1;
  width: 200%;
  right: -100%;
  &:nth-child(2) {
    top: 64%;
    animation: move 20s linear infinite;
  }
  &:nth-child(3) {
    top: 67%;
  }
  &:nth-child(4) {
    top: 70%;
    animation: move 20s linear infinite;
  }
}
.data__info-col.no-border {
  position: relative;
  .data__info-item {
    overflow: visible;
  }
}
.data__info-img {
  position: relative;
  &:after {
    content: '';
    width: calc(100% + 5px);
    height: 200%;
    background: $baseWhite;
    position: absolute;
    bottom: 0;
    left: -5px;
    z-index: -1;
    filter: blur(2px)
  }
}
.data__item.mb-15 .data__info-col {
  &:nth-child(3n + 3) {
    .data-text {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 5px;
        height: 90%;
        background: $baseWhite;
        left: -5px;
        top: 0;
        z-index: 2;
        filter: blur(2px)
      }
    }
  } 
}
.data__info-img {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 100%;
    background: $baseWhite;
    right: -5px;
    top: 0;
    z-index: 2;
    filter: blur(2px)
  }
}
.data__graph-vertical  {
  display: none;
  animation: numberDown-1 8s linear infinite;
  position: absolute;
  top: 99%;
  height: 200%;
  right: 26px;
  z-index: -1;
  opacity: .7;
  &.second {
    right: 36px;
    animation: numberDown-1 15s linear infinite;
  }
  &.third {
    right: 46px;
  }
  &.fourth {
    right: 56px;
    animation: numberDown-1 15s linear infinite;
  }
}
.data__graph-vertical-content {
  display: flex;
  flex-direction: column;
}
.data__info-graph-elem {
  opacity: 0;
  &.active {
    opacity: 1;
    animation: opacityShow 1s linear infinite;
  }
}
.data__graph-interval-wrap {
  position: absolute;
  top: 55%;
  z-index: 0;
  width: 200%;
  right: -128%;
}
.data__info-col:first-child {
  position: relative;
  z-index: 10;
  &:before {
    content: '';
    top: -30px;
    width: 100%;
    height: 30px;
    position: absolute;
    background: $baseWhite;
    left: 0;
    z-index: 1;
  }
}
.bars {
  transition: all 3s ease
}
.figures-wrap {
  position: relative;
}
.figures-item {
  color: $baseGrey;
  font-size: .8375rem;
  position: absolute;
  top: 25%;
  left: 18%;
  &:nth-child(2) {
    left: auto;
    right: 15%;
  }
}
.graph-intervals  {
  position: absolute;
  z-index: 2;
  &.centered {
    right: 3px;
    display: flex;
    height: 33px;
    top: 53.6%;
  }
}
.graph-interval {
  font-size: 10px;
  font-weight: bold;
  color: $baseGrey;
}
.graph-intervals {
  .fallen-text {
    transform:translate(205px, 0);
  }
  &:nth-child(2) {
    .fallen-text {
      transform:translate(205px, 0);
    }
  }
}
.figure, .figures-item {
  width: 26px;
}
.graph-intervals__wrap.centered {
  .graph-intervals {
    span {
      transition: transform 6s ease-in;
      display:inline-block;
    }
  }
}
.normal-text {
  transition: none;
}
.data__info-img-wrap {
  position: relative;
  &:after {
    content: '';
    background: $baseWhite;
    height: 55px;
    position: absolute;
    top: 50px;
    z-index: 3;
    filter: blur(4px);
  }
}
.data__info-graph {
  z-index: 10;
}
.interval-text {
  transition: opacity .8s ease;
}
.graph-elem-interval {
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: 22%;
  right: 0;
}
.code-interval {
  position: absolute;
  z-index: 10;
  top: 0;
}
.data__info-col:first-child {
  .data__info-item {
    overflow: visible;
  }
  .data__info-item-inner {
    overflow: visible;
    &:after {
      content: '';
      width: 5px;
      height: 100%;
      background: $baseWhite;
      position: absolute;
      right: -6px;
      top: 0;
      z-index: 5;
      filter: blur(3px);
    }
  }
}
.graph-intervals-center {
  position: absolute;
  width: 200px;
  top: 58%;
  height: 13px;
  right: 0;
  &-item {
    color: $baseGrey;
    font-weight: bold;
    font-size: 10px;
    position: relative;
  }
}
.data__info-col:first-child {
  .data__info-item {
    position: relative;
  }
}
.item-graph {
  position: relative;
  &:before {
    content: '';
    width: 10px;
    height: 10px;
    background: $baseWhite;
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 2;
  }
}
.data__info {
  overflow: hidden;
}
.data__info-elem {
  width: 200%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -200%;
  background: $baseWhite;
  z-index: -1;
}
.data__info-code {
  overflow: hidden;
  width: 200%;
  overflow: hidden;
  height: 200px;
  position: absolute;
  top: 37%;
  .data__graph-elems-wrap {
    top: 56%;
    animation-duration: 190s;
    &:nth-child(2) {
      top: 61%;
      animation-duration: 230.5s;
    }
    &:nth-child(3) {
      top: 66%;
      animation-duration:190s;
    }
    &:nth-child(4) {
      top: 71%;
      animation-duration: 230.5s;
    }
  }
}
.data__graph-wrap {
  height: 10000px;
  position: absolute;
  top: 0;
  left: 50%;
  width: 17px;
  transform: translateX(-50%);
  display: none;
}
.data__info-elem-vertical {
  height: 10000px;
  position: absolute;
  left: auto;
  right: 24px;
  width: 38px;
  overflow: hidden;
  top:-20px;
}
.data__info-elem-inner {
  height: 10000px;
  margin-top: 150px;
  top: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 38px;
}

.data__graph-elems-wrap.vertical {
  left: auto;
  &:first-child {
    right: 0;
  }

  &:nth-child(2) {
    right: 10px
  }
  &:nth-child(3) {
    right: 20px
  }
  &:nth-child(4) {
    right: 30px
  }
}
.data__info-item-inner {
  z-index: 10;
}
.data__info-elem {
  display: none
}
.data__info-col:first-child .data__info-item {
  &:after {
    content: '';
    width: 100%;
    height: 80px;
    background: $baseWhite;
    top: 50%;
    transform: translateY(-50%);
    right: -97%;
    position: absolute;
    max-width: 194px;
  }
}
.fallen-text {
  opacity: .8;
}
.graph-interval {
  p:first-child,  p:nth-child(2) {
    transform: rotate(16deg);
    position: absolute;
    top: -23px;
  }
  p:nth-child(2) {
    top: -35px;
  }
  p:last-child {
    position: absolute;
    transform: rotate(-16deg);
    bottom: -28px
  }
}
.data__info .data__info-col:first-child {
  .data__info-item {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: -6px;
      width: 5px;
      height: 80%;
      background: $baseWhite;
      filter: blur(4px)
    }
  }
}
.graph-intervals__start, .graph-intervals__elems-wrap {
  position: absolute;
  left: 85%;
  width: 120px;
  height: 135px;
  z-index: 2;
  top: 60%;
  transform: translateY(-50%);
  overflow: hidden;
}
.graph-intervals__start-top {
  position: absolute;
  left: 85%;
  width: 120px;
  height: 138px;
  top: 55px;
  z-index: 2;
  overflow: hidden
}
.graph-intervals__elems-wrap {
  left: auto;
  top: 58.9%;
  &.active {
    opacity: 1;
  }
}
.graph-intervals__item {
    position: absolute;
    color: $baseGrey;
    transition: transform 10s ease-in;
    &:nth-child(3n) {
      color: $thirdlyGreen;
    }
    &.animated {
      transform:translate(205px, 0);
    }
    &.animated:nth-child(-n+5){
      transform:translate(205px,120px);
    }
    &.animated:nth-child(6),  &.animated:nth-child(7), &.animated:nth-child(8), &.animated:nth-child(9) {
      transform:translate(205px,62px);
    }
    &.animated:nth-child(11),  &.animated:nth-child(10) {
      transform:translate(205px,55px);
    }
    &.animated:nth-child(16)  {
      transform:translate(205px, -45px);
    }
    &.animated:nth-child(19),  &.animated:nth-child(20),  &.animated:nth-child(21), &.animated:nth-child(21) {
      transform:translate(205px, -92px);
    }
    &.animated:nth-child(17),&.animated:nth-child(18), &.animated:nth-child(22), &.animated:nth-child(25) {
      transform:translate(205px, -130px);
    }
    &:first-child {
      left: 0;
      transition: transform 11s ease-in;
    }
    &:nth-child(2) {
      top: 9px;
      left: 42px;
    }
    &:nth-child(3) {
      top: 3px;
      left: 36px;
      transition: transform 11s ease-in;
    }
    &:nth-child(4) {
      top: 22px;
      left: 42px;
      transition: transform 8s ease-in;
    }
    &:nth-child(5) {
      top: 25px;
      left: 63px;
      transition: transform 12s ease-in;
    }
    &:nth-child(6) {
      top: 48px;
      left: 86px;
      transition: transform 12s ease-in;
    }
    &:nth-child(7) {
      top: 30px;
      left: 3px;
      transition: transform 11s ease-in;
    }
    &:nth-child(8) {
      top: 31px;
      left: 33px;
      transition: transform 10s ease-in;
    }
    &:nth-child(9) {
      top: 41px;
      left: 55px;
      transition: transform 11s ease-in;
    }
    &:nth-child(10) {
      top: 44px;
      left: 77px;
    }
    &:nth-child(11) {
      top: 40px;
      left: 5px;
    }
    &:nth-child(12) {
      top: 61px;
      left: 25px;
      transition: transform 9s ease-in;
    }
    &:nth-child(13) {
      top: 56px;
      left: 47px;
      transition: transform 9s ease-in;
    }
    &:nth-child(14) {
      top: 59px;
      left: 66px;
      transition: transform 9s ease-in;
    }
    &:nth-child(15) {
      top: 63px;
      left: 7px;
      transition: transform 10s ease-in;
    }
    &:nth-child(16) {
      top: 85px;
      left: 28px;
      transition: transform 9s ease-in;
    }
    &:nth-child(17) {
      top: 108px;
      left: 36px;
      transition: transform 11s ease-in;
    }
    &:nth-child(18) {
      top: 100px;
      left: 54px;
      transition: transform 9s ease-in;
    }
    &:nth-child(19) {
      top: 80px;
      left: 54px;
      transition: transform 10s ease-in;
    }
    &:nth-child(20) {
      top: 83px;
      left: 76px;
      transition: transform 10s ease-in;
    }
    &:nth-child(21) {
      top: 67px;
      left: 76px;
      transition: transform 10s ease-in;
    }
    &:nth-child(22) {
      top: 79px;
      left: 92px;
      transition: transform 10s ease-in;
    }
    &:nth-child(23) {
      top: 65px;
      left: 92px;
      transition: transform 10s ease-in;
    }
    &:nth-child(24) {
      top: 65px;
      left: 105px;
      transition: transform 10s ease-in;
    }
    &:nth-child(25) {
      top: 120px;
      left: 25px;
      transition: transform 10s ease-in;
    }
  }
.data__item-wrapper .graph-intervals__start-top {
  .graph-intervals__item {
    &.animated {
      &:first-child {
        transform:translate(205px, 205px);
      }
      transform:translate(205px, 233px);
      transition: transform 10s ease-in;
      &:nth-last-child(-n+5) {
        transform:translate(205px, 190px);
        transition: transform 11s ease-in;
      }
    }
  }
  .graph-intervals__item:nth-child(2) {
    top: 20px
  }
  .graph-intervals__item:nth-child(3) {
    top: 7px;
    left: 35px;
  }
  .graph-intervals__item:nth-child(4) {
    top: 39px;
    left: 60px;
  }
  .graph-intervals__item:nth-child(5) {
    top: 54px;
    left: 79px;
  }
  .graph-intervals__item:nth-child(6) {
    top: 71px;
    left: 92px;
  }
  .graph-intervals__item:nth-child(7) {
    top: 94px;
    left: 109px;
  }
  .graph-intervals__item:nth-child(8) {
    top: 37px;
    left: 33px;
  }
  .graph-intervals__item:nth-child(9) {
    top: 64px;
    left: 65px;
  }
  .graph-intervals__item:nth-child(10) {
    top: 87px;
    left: 89px;
  }
}
.data__info-img-wrap {
  &:before {
    content: '';
    width: 100px;
    height: 50px;
    background: $baseWhite;
    position: absolute;
    right: -50px;
    top: 70px;
  }
}
.data__info-item.data-no-border .data__graph-elems-wrap.vertical {
  width: 7px;
}
.graph-intervals__elems-top {
  position: absolute;
  right: -46%;
  width: 200px;
  height: 210px;
  top: -20px;
  z-index: 2;
  .graph-intervals__item {
    &.animated {
      &:nth-last-child(-n+5) {
        transition: transform 15s ease-in;
      }
      transform:translate(205px, 220px);
      transition: transform 11s ease-in;
      &:first-child {
        transform:translate(205px, 200px);
      }
      &:nth-last-child(2) {
        transform:translate(205px, 230px);
      }
      &:nth-child(5){
        transform:translate(205px, 240px);
        transition: transform 17s ease-in;
      }
      &:nth-child(6){
        transform:translate(205px, 250px);
      }
      &:nth-child(7){
        transform:translate(205px, 170px);
      }
      &:nth-child(8){
        transform:translate(205px, 200px);
        transition: transform 17s ease-in;
      }
      &:nth-child(9){
        transform:translate(205px, 210px);
      }
      &:last-child {
        transform:translate(205px, 240px);
        transition: transform 17s ease-in;
      }
    }
  }
}
.graph-intervals__elems-wrap   {
  right: -46%;
  width: 210px;
  height: 170px;
  .graph-intervals__item {
    transition: transform 10s ease-in;
    &:first-child {
      top: -32px;
    }
    &:nth-child(2) {
      top: -18px;
    }
    &:nth-child(3) {
      top: -20px;
    }
    &:nth-child(4) {
      top: -10px;
    }
    &:nth-child(5) {
      top: -10px;
    }
    &:nth-child(6) {
      top: 58px;
    }
    &:nth-child(7) {
      top: 22px;
    }
    &:nth-child(9) {
      top: 43px;
    }
    &:nth-child(10) {
      top: 50px;
    }
    &:nth-child(13),  &:nth-child(12) {
      top: 80px;
    }
    &:nth-child(11) {
      top: 99px;
    }
    &:nth-child(14) {
      top: 70px;
      transition: transform 10s ease-in;
    }
    &:nth-child(15) {
      top: 82px;
      transition: transform 11s ease-in;
    }
    &:nth-child(17) {
      top: 139px;
    }
    &:nth-child(18) {
      top: 137px;
    }
    &:nth-child(19) {
      top: 133px;
      left: 73px;
    }
    &:nth-child(20) {
      top: 127px;
      left: 91px;
      transition: transform 10s ease-in;
    }
    &:nth-child(22) {
      top: 121px;
      left: 100px;
    }
    &:nth-child(23) {
      top: 130px;
      left: 100px;
      transition: transform 10s ease-in;
    }
    &:nth-child(24) {
      top: 100px;
      left: 108px;
    }
    &:nth-child(25) {
      top: 140px;
      left: 108px;
    }
    &.animated:nth-child(11),  &.animated:nth-child(12),  &.animated:nth-child(13), &.animated:nth-child(19), &.animated:nth-child(20), &.animated:nth-child(22) {
      transform:translate(205px,-10px);
    }
    &.animated:nth-child(14), &.animated:nth-child(15),  &.animated:nth-child(16),    &.animated:nth-child(21){
      transform:translate(205px, 0px);
    }
    &.animated:nth-child(17), &.animated:nth-child(18),  &.animated:nth-child(20),  &.animated:nth-child(24) {
      transform:translate(205px,-60px);
    }
    &.animated:nth-child(19), &.animated:nth-child(22), &.animated:nth-child(23) {
      transform:translate(205px,-90px);
    }
    &.animated:nth-child(25) {
      transform:translate(205px,-125px);
    }
  }

}
.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 10s linear infinite;
}