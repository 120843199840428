
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto-Regular.woff2') format('woff2'),
  url('../fonts/Roboto-Regular.woff') format('woff'),
  url('../fonts/Roboto-Regular.ttf') format('truetype'),
  url('../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Bold.eot');
  src: url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto-Bold.woff2') format('woff2'),
  url('../fonts/Roboto-Bold.woff') format('woff'),
  url('../fonts/Roboto-Bold.ttf') format('truetype'),
  url('../fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

