$baseWhite: #ffffff;
$baseBlack: #000000;
$baseGreen: #025D63;
$lightGreen: #B0D05F;
$secondaryGreen: #73B98D;
$thirdlyGreen: #A7CE47;
$baseGrey: #A4A4A4;
$lightGrey: #E5E5E5;


$borderRadius-10: 10px;
$borderRadius-8: 8px;
$borderRadius-5: 5px;
$borderRadius-3: 3px;
$boxShadow: 0px 3px 25px rgba(0, 0, 0, 0.1);
$gradientBg: rgba(2, 93, 99, 0.1);
$gradientBorder: linear-gradient(139.9deg, #73B98D 11.81%, #B0D05F 83.71%);
$gradientTransparent: linear-gradient(270deg, #F8F8F8 41.78%, rgba(255, 255, 255, 0) 62.13%, #F8F8F8 81.85%);

$breakpoint-1100: 1100px;
$breakpoint-810: 810px;
$breakpoint-xs: 576px;
$breakpoint-625: 625px;
$breakpoint-xs-480: 480px;
$breakpoint-xxs: 360px;